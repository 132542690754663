p {
  font-size: 18px;
  line-height: 1.6;
}

.container-mw {
  max-width: 1064px;
}

.search-input {
  color: $light-color;
}

.site-title:hover {
  background-image: none !important;
}

.mp-header { 
  .site-header {
    border-bottom: none;
  }
  .main-header {
    background: none;
    border-bottom: none;
    justify-content: flex-end;
  }
  .search {
    display: none;
  }
  .main-header.nav-open {
    display: none;
  }

  @media (min-width: 50rem) {
    .site-nav {
      display: none;
    }
    .site-title {
      padding-left: 31px;
    }
  }
}

.mp-footer {
  .svg-icon {
    width: 24px;
  }

  a {
    color: #ffffff;
  }
  a:hover {
    text-decoration: underline;
  }
}

.site-header .site-title {
 height: 44px;
}

.light {
  color: #ffffff;

  a {
    color: #ffffff;
  }
  a.site-button:hover {
    background-image: linear-gradient(-90deg, #ffffff 0%, rgba(255, 255, 255, 0.1) 0%);
  }
  a.site-title:hover {
    background-image: none;
  }
}

.bg-brand {
  background-color: $brand-color;
}

/**
 * Lists
 */

.ul-checkmark {
  li {
    margin-bottom: 16px;
  }
}


/**
 * Fonts
 */

h1, h2, h3, h4, h5, h6 {
  font-family: canada-type-gibson, sans-serif;
  font-weight: 600 !important;
}

.main-content h1 {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}
@font-face {
  font-family: 'maparonicons';
  src: url('./../fonts/maparonicons/maparonicons.eot');
  src: url('./../fonts/maparonicons/maparonicons.eot#iefix') format('embedded-opentype'),
       url('./../fonts/maparonicons/maparonicons.woff') format('woff'),
       url('./../fonts/maparonicons/maparonicons.ttf') format('truetype'),
       url('./../fonts/maparonicons/maparonicons.svg#maparonicons') format('svg');
  font-weight: normal;
  font-style: normal;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  color: #ffffff;
  opacity: 0.8;
}

h2.mp-h {
  font-size: 32px !important;
}
h3.mp-h {
  font-size: 20px !important;
}
@media (min-width: 31.25rem) { 
  h1.mp-h {
    font-size: 48px !important;
  }
  h2.mp-h {
    font-size: 38px !important;
  }
  h3.mp-h {
    font-size: 24px !important;
  }  
}

.footnote {
  font-size: 14px;
  opacity: 0.7;
}

/**
 * Icons
 */
 .icon.icon-light {
  color: #ffffff;
}
 .svg-icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  fill: #{$grey-color};
  padding-right: 5px;
  vertical-align: text-top;
}

.social-media-list {
li + li {
  padding-top: 5px;
}
}
.maparonicon
{
  font-family: "maparonicons";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  /* margin-right: .2em;*/
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  /* margin-left: .2em; */

  /* You can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.grey-box {
  @media (prefers-color-scheme: dark) {
    background: #222;
  }
  background: #eee;
}
.shape-circular-top {
  background-image: url(/assets/images/hero-artwork-1.svg); 
  background-size: auto 150%; 
  background-position: center 105%; 
  background-repeat: no-repeat;

  @media (min-width: 34rem) { 
    background-size: auto auto; 
    background-position: center bottom; 
  }
}

.shape-concentric-right {
  background-image: url(/assets/images/bg-shape-2.svg); 
  background-size: auto auto; 
  background-position: 40% bottom; 
  background-repeat: no-repeat;

  @media (min-width: 34rem) { 
    background-position: 90% bottom; 
  }
}