$brand-color:             #D34F21 !default;
$secondary-color:         #112A34 !default;
$light-color:             #ffffff !default;
$grey-color:              #828282 !default;

$base-font-size:   16px !default;
$small-font-size:  $base-font-size * 0.875 !default;

$link-color: $brand-color;

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

@import "./_ruben", "./_bigfoot-default";


// FROM dark.scss
$body-background-color: $grey-dk-300;
$sidebar-color: $grey-dk-300;
$border-color: $grey-dk-200;

$body-text-color: $grey-lt-300;
$body-heading-color: $grey-lt-000;
$nav-child-link-color: $grey-dk-000;
$search-result-preview-color: $grey-dk-000;

$btn-primary-color: $blue-200;
$base-button-color: $grey-dk-250;

$search-background-color: $grey-dk-250;
$table-background-color: $grey-dk-250;
$feedback-color: darken($sidebar-color, 3%);

//
// The following highlight theme is more legible than that used for the light color scheme
//

$code-background-color: #1d1f21;

/*! tomorrow night; https://github.com/MozMorris/tomorrow-pygments */
.highlight, .highlight pre, .highlight table { background: #1d1f21 !important; color: #c5c8c6 !important; }
.highlight .hll { background-color: #373b41 !important; }
.highlight .c { color: #969896 !important; } /* Comment */
.highlight .err { color: #cc6666 !important; } /* Error */
.highlight .k { color: #b294bb !important; } /* Keyword */
.highlight .l { color: #de935f !important; } /* Literal */
.highlight .n, .highlight .h { color: #c5c8c6 !important; } /* Name */
.highlight .o { color: #8abeb7 !important; } /* Operator */
.highlight .p { color: #c5c8c6 !important; } /* Punctuation */
.highlight .cm { color: #969896 !important; } /* Comment.Multiline */
.highlight .cp { color: #969896 !important; } /* Comment.Preproc */
.highlight .c1 { color: #969896 !important; } /* Comment.Single */
.highlight .cs { color: #969896 !important; } /* Comment.Special */
.highlight .gd { color: #cc6666 !important; } /* Generic.Deleted */
.highlight .ge { font-style: italic !important; } /* Generic.Emph */
.highlight .gh { color: #c5c8c6 !important; font-weight: bold !important; } /* Generic.Heading */
.highlight .gi { color: #b5bd68 !important; } /* Generic.Inserted */
.highlight .gp { color: #969896 !important; font-weight: bold !important; } /* Generic.Prompt */
.highlight .gs { font-weight: bold !important; } /* Generic.Strong */
.highlight .gu { color: #8abeb7 !important; font-weight: bold !important; } /* Generic.Subheading */
.highlight .kc { color: #b294bb !important; } /* Keyword.Constant */
.highlight .kd { color: #b294bb !important; } /* Keyword.Declaration */
.highlight .kn { color: #8abeb7 !important; } /* Keyword.Namespace */
.highlight .kp { color: #b294bb !important; } /* Keyword.Pseudo */
.highlight .kr { color: #b294bb !important; } /* Keyword.Reserved */
.highlight .kt { color: #f0c674 !important; } /* Keyword.Type */
.highlight .ld { color: #b5bd68 !important; } /* Literal.Date */
.highlight .m { color: #de935f !important; } /* Literal.Number */
.highlight .s { color: #b5bd68 !important; } /* Literal.String */
.highlight .na { color: #81a2be !important; } /* Name.Attribute */
.highlight .nb { color: #c5c8c6 !important; } /* Name.Builtin */
.highlight .nc { color: #f0c674 !important; } /* Name.Class */
.highlight .no { color: #cc6666 !important; } /* Name.Constant */
.highlight .nd { color: #8abeb7 !important; } /* Name.Decorator */
.highlight .ni { color: #c5c8c6 !important; } /* Name.Entity */
.highlight .ne { color: #cc6666 !important; } /* Name.Exception */
.highlight .nf { color: #81a2be !important; } /* Name.Function */
.highlight .nl { color: #c5c8c6 !important; } /* Name.Label */
.highlight .nn { color: #f0c674 !important; } /* Name.Namespace */
.highlight .nx { color: #81a2be !important; } /* Name.Other */
.highlight .py { color: #c5c8c6 !important; } /* Name.Property */
.highlight .nt { color: #8abeb7 !important; } /* Name.Tag */
.highlight .nv { color: #cc6666 !important; } /* Name.Variable */
.highlight .ow { color: #8abeb7 !important; } /* Operator.Word */
.highlight .w { color: #c5c8c6 !important; } /* Text.Whitespace */
.highlight .mf { color: #de935f !important; } /* Literal.Number.Float */
.highlight .mh { color: #de935f !important; } /* Literal.Number.Hex */
.highlight .mi { color: #de935f !important; } /* Literal.Number.Integer */
.highlight .mo { color: #de935f !important; } /* Literal.Number.Oct */
.highlight .sb { color: #b5bd68 !important; } /* Literal.String.Backtick */
.highlight .sc { color: #c5c8c6 !important; } /* Literal.String.Char */
.highlight .sd { color: #969896 !important; } /* Literal.String.Doc */
.highlight .s2 { color: #b5bd68 !important; } /* Literal.String.Double */
.highlight .se { color: #de935f !important; } /* Literal.String.Escape */
.highlight .sh { color: #b5bd68 !important; } /* Literal.String.Heredoc */
.highlight .si { color: #de935f !important; } /* Literal.String.Interpol */
.highlight .sx { color: #b5bd68 !important; } /* Literal.String.Other */
.highlight .sr { color: #b5bd68 !important; } /* Literal.String.Regex */
.highlight .s1 { color: #b5bd68 !important; } /* Literal.String.Single */
.highlight .ss { color: #b5bd68 !important; } /* Literal.String.Symbol */
.highlight .bp { color: #c5c8c6 !important; } /* Name.Builtin.Pseudo */
.highlight .vc { color: #cc6666 !important; } /* Name.Variable.Class */
.highlight .vg { color: #cc6666 !important; } /* Name.Variable.Global */
.highlight .vi { color: #cc6666 !important; } /* Name.Variable.Instance */
.highlight .il { color: #de935f !important; } /* Literal.Number.Integer.Long */
